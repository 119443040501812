import React, { useState } from "react";
import { toast } from "react-toastify";
import TableComponent from "../../../components/Table/TableComponent";
import APICall, { explodeDataWithRowTable } from '../../../components/Api/APICall';
import { InputBoxInput } from '../../agent/pages/formdata/FormInputType';
import  dayjs from 'dayjs';

export default function MisscallReport() {
  const [isLoading, setIsLoading] = useState(false);

  const [DataList, setDataList] = useState({
    row: [],
    dataWithRows: [],
    onlyData: []
  });

  const [InpValue, setInpValue] = useState({})

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setInpValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const returnValue = (value) => {
    return value === undefined ? 0 : value;
  }
  const SearchReportHandler = (e) => {
    e.preventDefault();
    setDataList({
      row: [],
      dataWithRows: [],
      onlyData: []
    });
    setIsLoading(true);
    const req = {
      startDate: returnValue(InpValue.startDate),
      endDate: returnValue(InpValue.endDate),
    }
    APICall(`/elastisk/MissCallReport`, "POST", req).then(data => {
      if (data.status) {
        let temp = data.data.map(v=>({...v,calldate:dayjs(v.calldate).format("DD-MM-YYYY HH:mm:ss")}))
        explodeDataWithRowTable(temp, setDataList)
      }
      setIsLoading(false);
    }).catch(err => {
      setIsLoading(false);
      toast.error(err)
    })
  }
  return (
    <>
      <form method="post" onSubmit={SearchReportHandler}>
        <div className="grid grid-cols-2 gap-2 md:grid-cols-6">
          {/* {JSON.stringify(RowEditData)} */}
          <>
            <div>
              <InputBoxInput label="Start Date"
                data={{
                  id: "startDate",
                  name: "startDate",
                  value: InpValue.startDate,
                  type: "date",
                  required: "required"
                }}
                inputChangeHandler={inputChangeHandler}
              />
            </div>
            <div>
              <InputBoxInput label="End Date"
                data={{
                  id: "endDate",
                  name: "endDate",
                  value: InpValue.endDate,
                  type: "date",
                  required: "required"
                }}
                inputChangeHandler={inputChangeHandler}
              />
            </div>
          </>
          <div className="items-center text-center md:pt-7">
            {isLoading ? (
              <button
                type="button"
                disabled
                className="px-5 py-3 mt-3 mb-1 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-green-500 rounded outline-none active:bg-indigo-600 focus:outline-none"
              >
                <i className="pr-3 animate-spin fas fa-spinner"></i>
                Loading...
              </button>
            ) : (
              <button
                type="submit"
                className="px-5 py-3 mt-3 mb-1 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-green-500 rounded outline-none active:bg-indigo-600 focus:outline-none"
              >
                <i className="pr-3 fas fa-paper-plane"></i> Search Report
              </button>
            )}
          </div>
        </div>
      </form>
      {DataList.row.length > 0 ?
        <>
          <div className="relative flex flex-col w-full min-w-0 mb-6 break-words bg-white rounded shadow-lg">
            <TableComponent
              columns={DataList.row}
              data={DataList.dataWithRows}
              selectOption={{ show: false, }}
              pagination={true}
              exportData={true}
              TicketsTitle={`Miss Call Report Details`}
            />
          </div>
        </>
        : ""}
    </>
  );
}
