const SuperAdminMenuList = [
  {
    name: "Dashboard",
    path: "dashboard",
    icon: "home",
  }, {
    name: "User Managment",
    path: "user",
    icon: "user",
  }, {
    name: "Base Upload",
    path: "baseupload",
    icon: "upload",
  }, {
    name: "Campaign Management",
    path: "campaign",
    icon: "folder",
  },
  {
    name: "Reports",
    path: "reports",
    icon: "folder-open",
  }, {
    name: "Elastix Report",
    path: "elastix",
    icon: "folder",
  }, {
    name: "Agent Performance",
    path: "performance",
    icon: "users",
  },
];

export default SuperAdminMenuList;
