
import ManualCallReportPage from './ManualCallReportPage';
import ReportPage from './ReportPage';

import TabComponent from '../../../components/Tab/TabComponent';
import MisscallReport from './MisscallReport';
const TabData = [
  {
    title: 'Manual Calling Report',
    PageComponent: <ManualCallReportPage />
  },
  {
    title: 'Other Calling Report',
    PageComponent: <ReportPage />
  },{
    title: 'Miss Calling Report',
    PageComponent: <MisscallReport />
  },
]
export default function AllCampaigns() {
  return (
    <TabComponent tabData={TabData} />
  );
}