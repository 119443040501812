import { useSelector } from "react-redux";
import DashboardTodayCount from "../SuperAdmin/Dashboard/DashboardTodayCount";
import FetchLeadsPage from './pages/FetchLeadsPage';
import TabComponent from '../../components/Tab/TabComponent';

export default function DashboardAgent() {
  const { userId } = useSelector((state) => state.UserDataReducer.data);
const TabData = [
    {
      title: 'Today Count  Report',
      PageComponent: <DashboardTodayCount AdminDashboardUrl={"agentToday&agentId=" + userId} />
    },
    {
      title: 'Total Count  Report',
      PageComponent: <DashboardTodayCount AdminDashboardUrl={"agent&agentId=" + userId} />
    },
  ]

  return (
    <>
      <TabComponent tabData={TabData} />
      <FetchLeadsPage />

    </>
  );
}
