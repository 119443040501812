import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TableComponent from "../../../components/Table/TableComponent";
import APICall, { explodeDataWithRowTable } from '../../../components/Api/APICall';
import { InputBoxInput } from '../../agent/pages/formdata/FormInputType';

export default function ManualCallReportPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [RowEditData, setRowEditData] = useState([]);

  const [DataList, setDataList] = useState({
    row: [],
    dataWithRows: [],
    onlyData: []
  });

  const [InpValue, setInpValue] = useState({})

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setInpValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const returnValue = (value) => {
    return value === undefined ? 0 : value;
  }
  const SearchReportHandler = (e) => {
    e.preventDefault();
    setDataList({
      row: [],
      dataWithRows: [],
      onlyData: []
    });
    setIsLoading(true);
    const req = {
      startDate: returnValue(InpValue.startDate),
      endDate: returnValue(InpValue.endDate),
    }
    APICall(`/mc/getMCInteractionsReport`, "POST", req).then(data => {
      if (data.status) {
        explodeDataWithRowTable(data.data, setDataList)
      }
      setIsLoading(false);
    }).catch(err => {
      setIsLoading(false);
      toast.error(err)
    })
  }
  useEffect(() => {
    if (Object.keys(RowEditData).length > 0) {
      if (RowEditData.AccountCode !== undefined) {
        APICall(`/elastisk/recordingFile?AccountCode=${RowEditData.AccountCode}`).then((data) => {
          if (data.status) {
            if (data.message.length > 0) {
              const fileUrl = process.env.REACT_APP_CALL_RECORDING + data.data[0].RecordingFile;
              const openwin = window.open(fileUrl);
              const timInter = setInterval(() => {
                openwin.close();
                clearInterval(timInter)
              }, 2000);
            } else {
              toast.error("Sorry no recording available")
            }
          } else {
            toast.error("Ooops! Something went wrong")
          }
        })
      } else {
        toast.error("No recording id found")
      }
    }
  }, [RowEditData])

  return (
    <>
      <form method="post" onSubmit={SearchReportHandler}>
        <div className="grid grid-cols-2 gap-2 md:grid-cols-6">
          {/* {JSON.stringify(RowEditData)} */}
          <>
            <div>
              <InputBoxInput label="Start Date"
                data={{
                  id: "startDate",
                  name: "startDate",
                  value: InpValue.startDate,
                  type: "date",
                  required: "required"
                }}
                inputChangeHandler={inputChangeHandler}
              />
            </div>
            <div>
              <InputBoxInput label="End Date"
                data={{
                  id: "endDate",
                  name: "endDate",
                  value: InpValue.endDate,
                  type: "date",
                  required: "required"
                }}
                inputChangeHandler={inputChangeHandler}
              />
            </div>
          </>
          <div className="items-center text-center md:pt-7">
            {isLoading ? (
              <button
                type="button"
                disabled
                className="px-5 py-3 mt-3 mb-1 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-green-500 rounded outline-none  active:bg-indigo-600 focus:outline-none"
              >
                <i className="pr-3 animate-spin fas fa-spinner"></i>
                Loading...
              </button>
            ) : (
              <button
                type="submit"
                className="px-5 py-3 mt-3 mb-1 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-green-500 rounded outline-none active:bg-indigo-600 focus:outline-none"
              >
                <i className="pr-3 fas fa-paper-plane"></i> Search Report
              </button>
            )}
          </div>
        </div>
      </form>
      {DataList.row.length > 0 ?
        <>
          <div className="relative flex flex-col w-full min-w-0 mb-6 break-words bg-white rounded shadow-lg">
            <TableComponent
              columns={DataList.row}
              data={DataList.dataWithRows}
              selectOption={{ show: false, }}
              pagination={true}
              exportData={true}
              TicketsTitle={`Report Details`}
              action={
                {
                  setEditData: setRowEditData,
                  btnType: ["download"],
                }}
            />
          </div>
        </>
        : ""}
    </>
  );
}
