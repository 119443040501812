import TabComponent from '../../../components/Tab/TabComponent';
import AutodaillerCampaign from './Campaign/AutodaillerCampaign';
import FetchCampaigns from './Campaign/FetchCampaigns';
const TabData = [
  {
    title: 'Autodailer Campaign',
    PageComponent: <AutodaillerCampaign />
  },
  {
    title: 'Fetch Call Campaign',
    PageComponent: <FetchCampaigns />
  },
]
export default function AllCampaigns() {
  return (
    <TabComponent tabData={TabData} />
  );
}