import FetchLeadBaseUpload from './baseupload/FetchLeadBaseUpload';
import TabComponent from '../../../components/Tab/TabComponent';
import AutoDailerLeadsBaseUpload from './baseupload/AutoDailerLeadsBaseUpload';
const TabData = [
  {
    title: 'Fetch lead Base Upload',
    PageComponent: <FetchLeadBaseUpload />
  }, {
    title: 'Autodailer lead Base Upload',
    PageComponent: <AutoDailerLeadsBaseUpload />
  },
]
export default function BaseUpload() {
  return (
    <TabComponent tabData={TabData} />
  );
}